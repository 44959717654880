.game-area {
  position: relative;
  margin: 50px auto;
  width: 600px;
  height: 600px;
  background-color: rgba(178, 250, 194, 0.185);
  border: 2px solid rgba(0, 255, 0, 0.719);
}

.snake {
  position: absolute;
  width: 2%;
  height: 2%;
  /*clip-path: circle(46% at 50% 50%);/**/
  background-color: rgb(238, 255, 0);
  border: 1px solid rgb(255, 0, 0);
  z-index: 2;
}
.display-bar {
  position: relative;
  margin-top: 100.5%;
  width: 595px;
  height: 25px;
  background-color: rgb(0, 0, 0);

  border: 2px solid rgba(0, 255, 0, 0.719);
}
.display-bar-name {
  color: rgb(255, 255, 255);
  padding-left: 30px;
  float: left;
}
.display-bar-score {
  color: rgb(255, 255, 255);
  padding-right: 50px;
  float: right;
}

.snake-food {
  position: absolute;
  width: 2%;
  height: 2%;
  background: rgb(55, 0, 255);
  border: 1px solid rgb(229, 255, 0);
  z-index: 1;
}
.custom-padding {
  padding-top: 5%;
  padding-left: 20%;
  padding-right: 20%;
}
.custom-margin {
  margin-top: 5%;
}
